<template>
  <div>
    <div class="asdmnka" v-if="productosData.length > 0">
      <div v-if="showIncompleteProductButton" style="align-self: flex-end; display: flex;">
        <b-button v-if="showRestoreProductButton" class="mb-1" variant="success" @click="onClickRestoreProduct()">
          <b-spinner small label="Loading..." v-if="loadingRestore" />
          <small class="m-0" v-else>
            Restaurar producto incompleto
          </small>
        </b-button>
        <b-button class="mb-1 ml-1" variant="danger" @click="onClickIncompleteProduct()">
          <b-spinner small label="Loading..." v-if="loadingSet" />
          <small class="m-0" v-else>
            Marcar producto incompleto
          </small>
        </b-button>
      </div>
      <b-card :key="'producto-' + product.producto_id" v-for="product in productosData" class="ecommerce-card" no-body>
        <!-- Product Image -->
        <div class="item-img" style="padding: 0em 1em;">
          <b-img :src="urlResized(product.imagen)" :alt="`${product.nombre}-${product.producto_id}`" rounded width="90"
            height="90" />
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body style="padding: 0;">
          <div class="dcnt">
            <div class="item-name">
              <h6 class="mb-0 text-primary">
                {{ product.nombre }}
              </h6>
            </div>
            <span>Código: <b>{{ product.sku }}</b></span>

            <span>Precio Unitario:
              <b>${{ product.precioUnitario.toFixed(2) }}</b></span>


            <b-badge variant="light-dark" class="product-details-quantity">Cantidad: <b-badge variant="primary"
                class="ml-25">{{
                  product.cantidad }}</b-badge></b-badge>


            <span v-if="productIsIncomplete(product)" class="text-danger">Producto Incompleto:
              <b>{{ productIncompleteMessage(product) }}</b></span>

            <div v-if="product.observacion" class="mt-1 text-left p-1 rounded bg-light-warning">
              <b>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4 20v-6a8 8 0 1 1 16 0v6h1v2H3v-2h1zm2 0h12v-6a6 6 0 1 0-12 0v6zm5-18h2v3h-2V2zm8.778 2.808l1.414 1.414-2.12 2.121-1.415-1.414 2.121-2.121zM2.808 6.222l1.414-1.414 2.121 2.12L4.93 8.344 2.808 6.222zM7 14a5 5 0 0 1 5-5v2a3 3 0 0 0-3 3H7z" />
                </svg>
                Indicaciones</b>:
              {{ product.observacion }}
            </div>
            <br />
            <app-collapse v-if="product.lstModificadores.length > 0" type="border" accordion>
              <app-collapse-item title="Modificadores" class="mdc" :isVisible="true">
                <b-list-group>
                  <b-list-group-item :key="index + '[mod]'" v-for="(modificador, index) in product.lstModificadores">
                    <p class="mb-1">{{ modificador.modNombreInterno ?? modificador.ModNombreInterno }}</p>
                    <b-media :key="index + '[item]' + item.idCarritoItems" v-for="(item, index) in modificador.items"
                      class="mhj" no-body>
                      <b-media-aside>
                        <b-badge variant="light-dark">
                          {{ item.cantidad ?? item.Cantidad }}</b-badge>
                      </b-media-aside>
                      <b-media-body>
                        {{ item.nombre ?? item.Nombre }} <b v-if="(item.precio ?? item.Precio)">({{ (item.precio ??
                          item.Precio) ? (item.precio ?? item.Precio).toFixed(2) :
                          (0).toFixed(2) }})</b>
                      </b-media-body>
                    </b-media>
                  </b-list-group-item>
                </b-list-group>
              </app-collapse-item>
            </app-collapse>
          </div>
        </b-card-body>
        <div style="padding: 0em 1em;">
          <div class="text-center">
            <h6 class="m-0">SubTotal</h6>
            <h4 class="item-price m-0">
              <b-badge variant="light-primary"> ${{ product.subtotal.toFixed(2) }}</b-badge>
            </h4>
          </div>
        </div>
      </b-card>
      <b-modal id="modal-incomplete-product" ref="modal-incomplete-product-ref" @ok="setProductIncomplete"
        :title="modalIncompleteTitle" centered ok-title="Aceptar" cancel-title="Cancelar" ok-variant="danger"
        cancel-variant="outline-secondary" no-close-on-backdrop>
        <b-form-group label="Producto incompleto:" label-for="v-products">
          <v-select id="v-products" label="nombre" v-model="selectedProduct" :reduce="(p) => p.producto_id"
            :options="productosData" :clearable="false" placeholder="Seleccione el producto incompleto" />
        </b-form-group>
        <b-form-group>
          <b-form-input id="v-incomplete-message" placeholder="Mensaje" type="text" class="d-inline-block"
            v-model="incompleteMessage" />
        </b-form-group>
      </b-modal>
      <b-modal id="modal-restore-product" ref="modal-restore-product-ref" @ok="setProductComplete"
        :title="modalCompleteTitle" centered ok-title="Confirmar" cancel-title="Cancelar"
        cancel-variant="outline-secondary" no-close-on-backdrop>
        <b-form-group label="Producto incompleto:" label-for="v-products">
          <v-select id="v-products" label="nombreProducto" v-model="selectedProductComplete"
            :reduce="(p) => p.productoID" :options="incompleteProducts" :clearable="false"
            placeholder="Seleccione el producto incompleto" />
        </b-form-group>
      </b-modal>
    </div>
    <b-alert show v-else variant="danger" class="m-1">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="AlertCircleIcon" />
        <span class="ml-25">No hay productos para mostrar. Comunicarse con soporte@elrosado.com para reportar el
          problema</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BLink,
  BAlert,
  BBadge,
  BMedia,
  BButton,
  BAvatar,
  BSpinner,
  BCardBody,
  BListGroup,
  BMediaBody,
  BFormGroup,
  BFormInput,
  BMediaAside,
  BListGroupItem,
  BFormSpinbutton,
} from "bootstrap-vue";
import {
  isCallCenter,
} from "@/auth/utils";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BLink,
    BAlert,
    BBadge,
    BMedia,
    BButton,
    BAvatar,
    vSelect,
    BSpinner,
    BCardBody,
    BListGroup,
    BMediaBody,
    BFormGroup,
    BFormInput,
    BMediaAside,
    AppCollapse,
    BListGroupItem,
    AppCollapseItem,
    BFormSpinbutton,
    ToastificationContent,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    checkIncomplete: {
      type: Boolean,
      default: true,
    },
    productosData: {
      type: Array,
      required: false,
    },
    driverStates: {
      type: Array,
      required: true,
    },
    orderState: {
      type: String,
      required: true,
    },
    commerceType: {
      type: String,
      required: true,
    }
  }, data() {
    return {
      loading: true,
      loadingSet: false,
      selectedProduct: null,
      incompleteMessage: "",
      incompleteProducts: [],
      selectedProductComplete: null,
    }
  },
  created() {
    if (this.checkIncomplete)
      this.getProductsIncomplete();
  },
  methods: {
    computedSubtotal(product) {
      var subtotal = product.subtotal;
      if (product.lstModificadores.length > 0) {
        product.lstModificadores.forEach((mod) => {
          mod.items.forEach((item) => {
            subtotal += item.precio;
          });
        });
      }
      subtotal = subtotal.toFixed(2);
      return subtotal;
    },
    productIsIncomplete(product) {
      return this.incompleteProducts.some(i => i.productoID === product.producto_id);
    },
    productIncompleteMessage(product) {
      const aux = this.incompleteProducts.find(i => i.productoID === product.producto_id)
      return aux.mensajeIncompleto;
    },
    onClickRestoreProduct() {
      this.$refs["modal-restore-product-ref"].show();
    },
    onClickIncompleteProduct() {
      this.$refs["modal-incomplete-product-ref"].show();
    },
    getProductsIncomplete() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/ObtenerProductosIncompletos",
          body: JSON.stringify({
            OrdenID: this.orderId,
            tokenSesion: getAuthToken(),
          }),
        }).then(async (res) => {
          if (res.data.bOk) {
            this.incompleteProducts = res.data.lsproducto;
          } else {
            throw new Error(res.data.mensaje);
          }
        }).catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al obtener los productos incompletos. ${e.message}`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        }).finally(() => {
          this.loading = false;
        });
    },
    setProductComplete() {
      this.loadingRestore = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/RevertirProductoIncompletoOrden",
          body: JSON.stringify({
            OrdenID: this.orderId,
            ProductoID: this.selectedProductComplete,
            tokenSesion: getAuthToken(),
          }),
        }).then(async (res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Éxito",
                icon: "CheckIcon",
                text: `Se marcó el producto [${this.selectedProduct}] como completo.`,
                variant: "success",
              },
            });
            this.selectedProductComplete = null;
            this.getProductsIncomplete();
          } else {
            throw new Error(res.data.mensaje);
          }
        }).catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al actualizar el estado del producto. ${e.message}`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        }).finally(() => {
          this.loadingRestore = false;
        });
    },
    setProductIncomplete() {
      this.loadingSet = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/OrdenProductoIncompleto",
          body: JSON.stringify({
            OrdenID: this.orderId,
            Mensaje: this.incompleteMessage,
            ProductoID: this.selectedProduct,
            tokenSesion: getAuthToken(),
          }),
        }).then(async (res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Éxito",
                icon: "CheckIcon",
                text: `Se marcó el producto [${this.selectedProduct}] como incompleto.`,
                variant: "success",
              },
            });
            this.selectedProduct = null;
            this.incompleteMessage = "";
            this.getProductsIncomplete();
          } else {
            throw new Error(res.data.mensaje);
          }
        }).catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al actualizar el estado del producto. ${e.message}`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        }).finally(() => {
          this.loadingSet = false;
        });
    },
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/80x80" + url.pathname;
        return url.href;
      } catch (error) {
        return this.$store.state.app.placeholderImageURL;
      }
    },
  },
  computed: {
    isCallCenter,
    modalCompleteTitle() {
      return `¿Esta seguro que el producto esta completo y desea revertir el cambio?`;
    },
    modalIncompleteTitle() {
      return `¿Esta seguro que la orden se enviará incompleta?`;
    },
    showRestoreProductButton() {
      if (this.incompleteProducts.length > 0)
        return true;
      return false;
    },
    showIncompleteProductButton() {
      if (this.commerceType === "MARKET") {
         /* 
        User info:
        **************************************************************************************************
          Michelle solicito que solo se muestre para ordenes de Market
        **************************************************************************************************   
        */
        if (this.isCallCenter) return true;
        /* 
        User info:
        **************************************************************************************************
         CallCenter es el único que tendrá siempre disponible la opción de marcar incompleta o revertir,  
         es decir, podrá marca una orden como incompleta incluso después de haberse entregado. 
         Esto nos servirá en los casos de los locales que envían sus órdenes incompletas sin darse cuenta.
        **************************************************************************************************   
        */
        if (["CR", "EP", "PR"].some(allowedState => allowedState === this.orderState)) {
          return !this.driverStates.some(s => s.codigo === "EL");
        }
      }
      return false;
    },
  },
};
</script>


<style>
.item-img img {
  justify-content: left !important;
}

.ecommerce-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1em 0em !important;
}

.ecommerce-card .card-body .dcnt {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ecommerce-card span {
  font-size: 0.85em !important;
}

.mhj {
  font-size: 12px;
  margin-top: 2px;
  line-height: 14px;
  font-weight: bold;
  margin-bottom: 2px;
}

.mdc .media {
  margin-bottom: 8px;
}

.asdmnka {
  display: flex;
  flex-direction: column;
}

.mdc .card-header {
  padding: 0.6rem 2.8rem 0.6rem 1rem !important;
}

.product-details-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
</style>
