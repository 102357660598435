var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "datos-cliente",
    attrs: {
      "title": "Datos del Cliente"
    }
  }, [_c('b-row', [_vm.hasTags ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    class: ['rounded', 'mb-1', _vm.tagVariant(_vm.ordenData.cliente[0].etiqueta)]
  }, [_c('h4', {
    staticClass: "p-1"
  }, [_vm._v(" " + _vm._s(_vm.getTags) + " ")])])]) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Nombre")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getNombre) + " " + _vm._s(_vm.getApellido) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CreditCardIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Cédula/RUC")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getTipoIdentificacion) + " - " + _vm._s(_vm.getIdentificacion) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Dirección")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getDireccion) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FlagIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Referencia")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getDireccionReferencia) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PhoneIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Teléfono")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getTelefono) + " ")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }