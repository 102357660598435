<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="meetup-header d-flex align-items-center mb-2">
          <div class="meetup-day">
            <h6 class="mb-0">CANT.</h6>
            <h3 class="mb-0">
              <b-badge variant="light-primary">
                {{ ordersData.length }}
              </b-badge>
            </h3>
          </div>
          <div class="my-auto">
            <b-card-text class="mb-0"> Ordenes </b-card-text>
            <b-card-title class="mb-25"> Preparadas </b-card-title>
          </div>
        </div>
        <!--/ metting header -->

        <!-- media -->
        <div class="media-bordered op">
          <b-media v-for="order in ordersData" :key="order.idorden" no-body class="py-1 cp"
            @click="onOrderClick(order)">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <feather-icon :icon="'PackageIcon'" size="18" />
              </b-avatar>
            </b-media-aside>

            <b-media-body>
              <div class="cntr">
                <div>
                  <h6 class="mb-0">#{{ order.idorden }}
                    <b-badge :variant="order.tipoentrega === 'IM' ? 'light-primary' : 'light-warning'">
                      <feather-icon :icon="order.tipoentrega === 'IM' ? 'ClockIcon' : 'CalendarIcon'
                        " size="18" class="mr-25" />
                      <small>{{ order.tipoentrega === 'IM' ? 'Inmediata' : 'Programada' }}</small>
                    </b-badge>
                  </h6>
                  <small>{{ order.cliente[0]?.nombreCompleto ?? 'Error: Orden sin cliente' }}</small>
                  <p v-if="order.fechaprogramada !== null" style="font-weight: bold;font-size: 10px;" class="mb-25">
                    Fecha programada: {{ formattedDate(order.fechaprogramada) }}
                  </p>
                  <p style="font-weight: bold;font-size: 10px;" class="m-0">
                    Fecha creación: {{ formattedDateCreated(order.fechacreacion) }}
                  </p>
                </div>
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-card-body>
    </b-card>
    <!-- modal orden -->
    <b-modal id="modal-orden" size="xl" :title="'Pedido #' + ordenData.idorden" v-model="showModal" centered scrollable
      hide-footer>
      <b-row>
        <b-col cols="12" md="5">
          <b-row>
            <b-col cols="12">
              <orden-card :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <cliente-card :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <gacela-card v-if="ordenData.lstRepartidor" :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <detalles-card :orden-data="ordenData" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="7">
          <productos-card :productos-data="ordenData.lstProductos" :order-id="ordenData.idorden"
            :driver-states="ordenData.lstEstadosMotorizado" :order-state="ordenData.estadoorden"
            :commerce-type="ordenData.lineadenegocio" />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BMedia,
  BAvatar,
  BCardBody,
  BCardText,
  BMediaBody,
  BCardTitle,
  BCardHeader,
  BMediaAside,
} from "bootstrap-vue";
import moment from "moment";
import OrdenCard from "./OrdenCard2.vue";
import ClienteCard from "./ClienteCard.vue";
import DetallesCard from "./DetallesCard.vue";
import ProductosCard from "./ProductosCard.vue";
import GacelaCard from "./GacelaCard.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BMedia,
    BAvatar,
    BCardBody,
    OrdenCard,
    BCardText,
    BMediaBody,
    BCardTitle,
    ClienteCard,
    BCardHeader,
    BMediaAside,
    DetallesCard,
    ProductosCard,
    GacelaCard,
    VuePerfectScrollbar,
  },
  props: {
    ordersData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      ordenData: {
        idorden: 0,
      },
      perfectScrollbarSettings: { maxScrollbarLength: 150 },
    };
  },
  methods: {
    onOrderClick(order) {
      this.ordenData = order;
      this.showModal = true;
    },
    formattedDate(originalDate) {
      if (originalDate === null) return "";
      const dateFormatted = moment(originalDate).format("DD/MM/YYYY hh:mm a");
      return `[${dateFormatted}]`;
    },
    formattedDateCreated(originalDate) {
      if (originalDate === null) return "";
      const dateFormatted = moment(originalDate).format("DD/MM/YYYY hh:mm a");
      return dateFormatted;
    },
  },
};
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

.meetup-header .meetup-day {
  border-right: 1px solid #d8d6de;
  padding-right: 1.3rem;
  margin-right: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#modal-orden .modal-body {
  max-height: 82vh;
}

#modal-orden .modal-dialog {
  max-width: 100vw;
}
</style>
