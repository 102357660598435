<template>
  <b-modal id="modal-preparacion" size="lg" @hide="$emit('hide')" :title="'Iniciar preparación de la orden #' + ordenId"
    v-model="mShow" centered hide-footer>
    <div class="ldng" v-if="loading">
      <h5>Iniciando preparación...</h5>
      <b-spinner />
    </div>
    <form-wizard v-else color="#04a82c" class="wizard-vertical" layout="vertical" :title="null" :subtitle="null"
      step-size="xs" @on-complete="formSubmitted" back-button-text="Volver" next-button-text="Siguiente"
      finish-button-text="Preparar la orden">
      <!-- numero de factura tab -->
      <tab-content title="Factura">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Ingrese el número de factura</h5>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-input id="facturaIn1" type="text" placeholder="000" v-model="factura1"
              :formatter="(s) => formatter(s, 3)" />
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-input id="facturaIn2" type="text" placeholder="000" v-model="factura2"
              :formatter="(s) => formatter(s, 3)" />
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-input id="facturaIn3" type="text" placeholder="000000000" v-model="factura3"
              :formatter="(s) => formatter(s, 9)" />
          </b-col>
        </b-row>
      </tab-content>

      <!-- address -->
      <tab-content title="Tiempo">
        <b-row v-if="currentState === 'PG'">
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Ingrese el tiempo de preparación de la orden</h5>
          </b-col>
          <b-col cols="12" lg="12" class="mt-2" style="display: flex;flex-direction: column;align-items: flex-start;">
            <label for="sb-inline">Minutos: </label>
            <b-form-spinbutton min="1" max="40" id="sb-inline" v-model="tiempo" inline aria-label="Minutos" />
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" class="mb-1">
            <h5 class="mb-0 text-center">Tiempo de preparación de la orden</h5>
          </b-col>
          <b-col cols="12" style="display: flex;justify-content: center;">
            <b-badge variant="dark">
              <h4 style="color: white;" class="m-0"> {{ tiempo }} Minutos </h4>
            </b-badge>
          </b-col>
        </b-row>
      </tab-content>

      <!-- social link -->
      <tab-content v-if="currentState === 'PG'" title="Configurar">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              ¿Quieres configurar tu tiempo de preparación por defecto en
              <b>{{ tiempo }} minutos</b>?
            </h5>
          </b-col>
          <b-col cols="12" lg="12" class="mt-2">
            <b-form-group>
              <b-form-radio-group id="btn-radios-1" v-model="selectedRadio" button-variant="outline-primary"
                :options="optionsRadio" buttons name="radios-btn-default" />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSpinbutton,
} from "bootstrap-vue";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BSpinner,
    FormWizard,
    TabContent,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormSpinbutton,
  },
  props: {
    ordenId: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    ordenTiempoPreparacion: {
      type: String
    },
    numeroFactura: {
      type: String,
      default: "",
    },
    currentState: {
      type: String,
      default: "",
    }
  },
  beforeMount() {
    this.tiempo = Number(this.ordenTiempoPreparacion);
    if (this.numeroFactura !== "") {
      const splitted = this.numeroFactura.split("-");
      if (splitted.length > 2) {
        this.factura1 = splitted[0];
        this.factura2 = splitted[1];
        this.factura3 = splitted[2];
      }
    }
  },
  watch: {
    show: function (newVal) {
      if (newVal) {
        this.mShow = true;
      }
    },
    ordenTiempoPreparacion: function (newValue) {
      this.tiempo = Number(newValue)
    }
  },
  data() {
    return {
      mShow: false,
      tiempo: Number(this.ordenTiempoPreparacion) ?? 10,
      loading: false,
      factura1: "000",
      factura2: "000",
      factura3: "000000000",
      selectedRadio: "inactive",
      optionsRadio: [
        { text: "Configurar", value: "active" },
        { text: "No configurar", value: "inactive" },
      ],
    };
  },
  methods: {
    formatter(value, length) {
      const regExp = /[a-zA-Z]/g;
      value = value.replace(regExp, "");
      if (value.length > length) return value.substring(1);
      else if (value.length === length) return value;
      else return "0" + value;
    },
    formSubmitted() {
      const factura = `${this.factura1}-${this.factura2}-${this.factura3}`;
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/ActualizaEstadoOrden",
          body: JSON.stringify({
            opt: "FTE",
            empresa: 2,
            idOrden: this.ordenId,
            factura: factura,
            tokenSesion: getAuthToken(),
            tiempoTienda: this.selectedRadio === "active",
            tiempoPreparacion: this.tiempo,
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.mShow = false;
            this.callback();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Éxito",
                icon: "CheckIcon",
                text: "Se cambio correctamente el estado de la orden.",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al cambiar el estado de la orden(${e.message}).`,
              variant: "danger",
            },
          });
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.vue-form-wizard {
  box-shadow: none !important;
}

.ldng {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em;
}

@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
