<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="meetup-header d-flex align-items-center mb-2">
          <div class="meetup-day">
            <h6 class="mb-0">CANT.</h6>
            <h3 class="mb-0">
              <b-badge variant="light-primary">
                {{ sortedOrders.length }}
              </b-badge>
            </h3>
          </div>
          <div class="my-auto">
            <b-card-text class="mb-0"> Ordenes </b-card-text>
            <b-card-title class="mb-25"> Pendientes </b-card-title>
          </div>
        </div>

        <div class="media-bordered op">
          <b-media v-for="order in sortedOrders" :key="order.idorden" no-body class="py-1 cp"
            @click="onOrderClick(order)">
            <b-media-aside class="mr-1">

              <b-avatar rounded variant="light-primary" size="34">
                <countdown :time="calcPrepEndTime(order)" v-slot="{ minutes }">
                  <div style="display: flex">
                    <radial-progress-bar :diameter="35" :stopColor="`#04a82c`" :startColor="`#04a82c`" :strokeWidth="3"
                      :total-steps="15" :animateSpeed="800" :completed-steps="minutes" :innerStrokeWidth="4"
                      :innerStrokeColor="`#E5E5E5`">
                      {{ minutes }}
                    </radial-progress-bar>
                  </div>
                </countdown>
              </b-avatar>
            </b-media-aside>
            <b-media-body>

              <h6 class="mb-0">#{{ order.idorden }}
                <b-badge :variant="order.tipoentrega === 'IM' ? 'light-primary' : 'light-warning'">
                  <feather-icon :icon="order.tipoentrega === 'IM' ? 'ClockIcon' : 'CalendarIcon'
                    " size="18" class="mr-25" />
                  <small>{{ order.tipoentrega === 'IM' ? 'Inmediata' : 'Programada' }}</small>
                </b-badge>
              </h6>
              <small>{{ order.cliente[0]?.nombreCompleto ?? 'Error: Orden sin cliente' }}</small>
              <p v-if="order.fechaprogramada !== null && order.tipoentrega === 'PG'"
                style="font-weight: bold;font-size: 10px;" class="mb-0">
                Fecha programada: {{ formattedDate(order.fechaprogramada) }}
              </p>
              <p style="font-weight: bold;font-size: 10px;" class="m-0">
                Fecha creación: {{ formattedDateCreated(order.fechacreacion) }}
              </p>
            </b-media-body>
          </b-media>
        </div>
      </b-card-body>
    </b-card>
    <!-- modal orden -->
    <b-modal id="modal-orden" @ok="onClickPreparar" size="xl" :title="'Pedido #' + ordenData.idorden"
      v-model="showModal" @cancel="onClickCancelar" centered ok-title="INICIAR PREPARACIÓN" scrollable
      cancel-title="CANCELAR PEDIDO" cancel-variant="danger">
      <b-row>
        <b-col cols="12" md="5">
          <b-row>
            <b-col cols="12">
              <orden-card :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <cliente-card :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <detalles-card :orden-data="ordenData" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="7">
          <productos-card :productos-data="ordenData.lstProductos" :order-id="ordenData.idorden"
            :driver-states="ordenData.lstEstadosMotorizado" :order-state="ordenData.estadoorden"
            :commerce-type="ordenData.lineadenegocio" />
        </b-col>
      </b-row>
    </b-modal>
    <preparacion-modal :show="showModalPreparar" @hide="hidePreparacionModal" :callback="callback"
      :orden-id="ordenData.idorden" :orden-tiempo-preparacion="ordenData.tiempopreparacion" 
      :current-state="ordenData.estadoorden"
      />
    <cancelar-modal :show="showModalCancelar" @hide="hideCancelarModal" :callback="callback"
      :orden-id="ordenData.idorden" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BMedia,
  BAvatar,
  BCardBody,
  BCardText,
  BMediaBody,
  BCardTitle,
  BCardHeader,
  BMediaAside
} from "bootstrap-vue";
import moment from "moment";
import ClienteCard from "./ClienteCard.vue";
import OrdenCard from "./OrdenCard2.vue";
import RadialProgressBar from "vue-radial-progress";
import Countdown from "@chenfengyuan/vue-countdown";
import DetallesCard from "./DetallesCard.vue";
import ProductosCard from "./ProductosCard.vue";
import CancelarModal from "./CancelarModal.vue";
import PreparacionModal from "./PreparacionModal.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ToastificationInvertedContent from "@core/components/toastification/ToastificationInvertedContent.vue";
const soundNotification = new Audio(
  require("../../../assets/sounds/alert.mp3")
);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BMedia,
    BAvatar,
    BCardBody,
    BCardText,
    BMediaBody,
    BCardTitle,
    BCardHeader,
    BMediaAside,
    ClienteCard,
    DetallesCard,
    CancelarModal,
    ProductosCard,
    PreparacionModal,
    VuePerfectScrollbar,
    RadialProgressBar,
    Countdown,
    OrdenCard,
  },
  props: {
    ordersData: {
      type: Array,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },
  watch: {
    ordersData: function (newVal) {
      this.mOrdersData = this.filteredOrders();
    },
  },
  data() {
    return {
      ordenData: {
        idorden: 0
      },
      mOrdersData: [],
      showModal: false,
      intervalAlert: null,
      intervalDuration: 120000,
      showModalCancelar: false,
      showModalPreparar: false,
      perfectScrollbarSettings: { maxScrollbarLength: 150 }
    };
  },
  beforeMount() {
    this.mOrdersData = this.filteredOrders();
    clearInterval(this.intervalAlert);
  },
  mounted() {
    if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'staging' ) {
      //Para que no moleste en modo desarrollo
      this.initAlert();
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalAlert);
  },
  methods: {
    initAlert() {
      this.intervalAlert = setInterval(() => {
        this.mOrdersData = this.filteredOrders();
        if (this.ordersData.length > 0) {
          const timeout = 1000 * 15;
          this.mOrdersData.forEach((order, index) => {
            setTimeout(() => {
              this.$toast.success(
                {
                  component: ToastificationInvertedContent,
                  props: {
                    icon: "BellIcon",
                    text: `No olvides atender la orden.`,
                    title: `Orden #${order.idorden} Pendiente`,
                    draggable: true,
                    closeButton: "button",
                    closeOnClick: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    draggablePercent: 0.6,
                    pauseOnFocusLoss: false,
                    showCloseButtonOnHover: false
                  }
                },
                {
                  timeout
                }
              );
            }, index * 600);
          });
          if (soundNotification) {
            try {
              soundNotification.muted = false;
              soundNotification.play();
              console.log(`Sonido Orden Pendiente ${order.idorden}`)
            } catch (e) { }
          }
        }
      }, this.intervalDuration);
    },
    calcPrepEndTime(orden) {
      const ordenEnPreparacion = orden.lstEstadosOrden.filter(oe => oe.codigo === 'PG' || oe.codigo === 'CR')
      const size = ordenEnPreparacion.length - 1;
      if (size >= 0) {
        const endTime = moment(ordenEnPreparacion[size].fecha).add(
          15,
          "minutes"
        );
        const minutesRemain = moment.duration(endTime.diff(new Date(this.currentDate()))).asMinutes();
        return minutesRemain > 0 ? minutesRemain * 60 * 1000 : 0;
      } else {
        return 0;
      }
    },
    currentDate() {
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0'); // Agregar ceros a la izquierda si es necesario
      const dia = fechaActual.getDate().toString().padStart(2, '0');
      const hora = fechaActual.toLocaleString('en-US', { hour: 'numeric', hour12: true }).toString().replace(/\s[APM]{2}$/, '').padStart(2, '0'); // Eliminar AM o PM
      const minutos = fechaActual.getMinutes().toString().padStart(2, '0');
      const segundos = fechaActual.getSeconds().toString().padStart(2, '0');
      const fechaHoraEnFormato = `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
      return fechaHoraEnFormato;
    },
    formattedDate(originalDate) {
      if (originalDate === null) return "";
      const dateFormatted = moment(originalDate).format("DD/MM/YYYY hh:mm a");
      return dateFormatted;
    },
    formattedDateCreated(originalDate) {
      if (originalDate === null) return "";
      const dateFormatted = moment(originalDate).format("DD/MM/YYYY hh:mm a");
      return dateFormatted;
    },
    onClickPreparar(e) {
      e && e.preventDefault();
      this.showModal = false;
      setTimeout(() => {
        this.showModalPreparar = true;
      }, 100);
    },
    onClickCancelar(e) {
      e && e.preventDefault();
      this.showModal = false;
      setTimeout(() => {
        this.showModalCancelar = true;
      }, 50);
    },
    onOrderClick(order) {
      this.ordenData = order;
      this.showModal = true;
    },
    hideCancelarModal() {
      this.showModalCancelar = false;
    },
    hidePreparacionModal() {
      this.showModalPreparar = false;
    },
    filteredOrders() {
      const filtered = this.ordersData.filter(order => {
        if (order.fechaprogramada !== null && order.tipoentrega === 'PG') {
          /**
           * [P313] 
           * Se solicito que se muestre las ordenes programadas solo 
           * 30 minutos antes de la fecha y hora programada
           */
          return moment().isAfter(moment(`${order.fechaprogramada}`).subtract(30, 'minutes'));
        }
        return true;
      });
      return filtered;
    }
  },
  computed: {
    sortedOrders() {
      return this.mOrdersData.sort((a, b) => a.idorden - b.idorden);
    }
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

.meetup-header .meetup-day {
  border-right: 1px solid #d8d6de;
  padding-right: 1.3rem;
  margin-right: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cp {
  cursor: pointer;
}

#modal-orden .modal-body {
  max-height: 82vh;
}

#modal-orden .modal-dialog {
  max-width: 100vw;
}
</style>
