var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-preparacion",
      "size": "lg",
      "title": 'Iniciar preparación de la orden #' + _vm.ordenId,
      "centered": "",
      "hide-footer": ""
    },
    on: {
      "hide": function hide($event) {
        return _vm.$emit('hide');
      }
    },
    model: {
      value: _vm.mShow,
      callback: function callback($$v) {
        _vm.mShow = $$v;
      },
      expression: "mShow"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "ldng"
  }, [_c('h5', [_vm._v("Iniciando preparación...")]), _c('b-spinner')], 1) : _c('form-wizard', {
    staticClass: "wizard-vertical",
    attrs: {
      "color": "#04a82c",
      "layout": "vertical",
      "title": null,
      "subtitle": null,
      "step-size": "xs",
      "back-button-text": "Volver",
      "next-button-text": "Siguiente",
      "finish-button-text": "Preparar la orden"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Factura"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Ingrese el número de factura")])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn1",
      "type": "text",
      "placeholder": "000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 3);
      }
    },
    model: {
      value: _vm.factura1,
      callback: function callback($$v) {
        _vm.factura1 = $$v;
      },
      expression: "factura1"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn2",
      "type": "text",
      "placeholder": "000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 3);
      }
    },
    model: {
      value: _vm.factura2,
      callback: function callback($$v) {
        _vm.factura2 = $$v;
      },
      expression: "factura2"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn3",
      "type": "text",
      "placeholder": "000000000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 9);
      }
    },
    model: {
      value: _vm.factura3,
      callback: function callback($$v) {
        _vm.factura3 = $$v;
      },
      expression: "factura3"
    }
  })], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Tiempo"
    }
  }, [_vm.currentState === 'PG' ? _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Ingrese el tiempo de preparación de la orden")])]), _c('b-col', {
    staticClass: "mt-2",
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "flex-start"
    },
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": "sb-inline"
    }
  }, [_vm._v("Minutos: ")]), _c('b-form-spinbutton', {
    attrs: {
      "min": "1",
      "max": "40",
      "id": "sb-inline",
      "inline": "",
      "aria-label": "Minutos"
    },
    model: {
      value: _vm.tiempo,
      callback: function callback($$v) {
        _vm.tiempo = $$v;
      },
      expression: "tiempo"
    }
  })], 1)], 1) : _c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0 text-center"
  }, [_vm._v("Tiempo de preparación de la orden")])]), _c('b-col', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": "dark"
    }
  }, [_c('h4', {
    staticClass: "m-0",
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.tiempo) + " Minutos ")])])], 1)], 1)], 1), _vm.currentState === 'PG' ? _c('tab-content', {
    attrs: {
      "title": "Configurar"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" ¿Quieres configurar tu tiempo de preparación por defecto en "), _c('b', [_vm._v(_vm._s(_vm.tiempo) + " minutos")]), _vm._v("? ")])]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "btn-radios-1",
      "button-variant": "outline-primary",
      "options": _vm.optionsRadio,
      "buttons": "",
      "name": "radios-btn-default"
    },
    model: {
      value: _vm.selectedRadio,
      callback: function callback($$v) {
        _vm.selectedRadio = $$v;
      },
      expression: "selectedRadio"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }