var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('div', {
    staticClass: "meetup-header d-flex align-items-center mb-2"
  }, [_c('div', {
    staticClass: "meetup-day"
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v("CANT.")]), _c('h3', {
    staticClass: "mb-0"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.ordersData.length) + " ")])], 1)]), _c('div', {
    staticClass: "my-auto"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Ordenes ")]), _c('b-card-title', {
    staticClass: "mb-25"
  }, [_vm._v(" Preparadas ")])], 1)]), _c('div', {
    staticClass: "media-bordered op"
  }, _vm._l(_vm.ordersData, function (order) {
    var _order$cliente$0$nomb, _order$cliente$;
    return _c('b-media', {
      key: order.idorden,
      staticClass: "py-1 cp",
      attrs: {
        "no-body": ""
      },
      on: {
        "click": function click($event) {
          return _vm.onOrderClick(order);
        }
      }
    }, [_c('b-media-aside', {
      staticClass: "mr-1"
    }, [_c('b-avatar', {
      attrs: {
        "rounded": "",
        "variant": "light-primary",
        "size": "34"
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": 'PackageIcon',
        "size": "18"
      }
    })], 1)], 1), _c('b-media-body', [_c('div', {
      staticClass: "cntr"
    }, [_c('div', [_c('h6', {
      staticClass: "mb-0"
    }, [_vm._v("#" + _vm._s(order.idorden) + " "), _c('b-badge', {
      attrs: {
        "variant": order.tipoentrega === 'IM' ? 'light-primary' : 'light-warning'
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": order.tipoentrega === 'IM' ? 'ClockIcon' : 'CalendarIcon',
        "size": "18"
      }
    }), _c('small', [_vm._v(_vm._s(order.tipoentrega === 'IM' ? 'Inmediata' : 'Programada'))])], 1)], 1), _c('small', [_vm._v(_vm._s((_order$cliente$0$nomb = (_order$cliente$ = order.cliente[0]) === null || _order$cliente$ === void 0 ? void 0 : _order$cliente$.nombreCompleto) !== null && _order$cliente$0$nomb !== void 0 ? _order$cliente$0$nomb : 'Error: Orden sin cliente'))]), order.fechaprogramada !== null ? _c('p', {
      staticClass: "mb-25",
      staticStyle: {
        "font-weight": "bold",
        "font-size": "10px"
      }
    }, [_vm._v(" Fecha programada: " + _vm._s(_vm.formattedDate(order.fechaprogramada)) + " ")]) : _vm._e(), _c('p', {
      staticClass: "m-0",
      staticStyle: {
        "font-weight": "bold",
        "font-size": "10px"
      }
    }, [_vm._v(" Fecha creación: " + _vm._s(_vm.formattedDateCreated(order.fechacreacion)) + " ")])])])])], 1);
  }), 1)])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-orden",
      "size": "xl",
      "title": 'Pedido #' + _vm.ordenData.idorden,
      "centered": "",
      "scrollable": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('orden-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('cliente-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.ordenData.lstRepartidor ? _c('gacela-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('detalles-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('productos-card', {
    attrs: {
      "productos-data": _vm.ordenData.lstProductos,
      "order-id": _vm.ordenData.idorden,
      "driver-states": _vm.ordenData.lstEstadosMotorizado,
      "order-state": _vm.ordenData.estadoorden,
      "commerce-type": _vm.ordenData.lineadenegocio
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }