<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener las ordenes {{ error }}
      </div>
    </b-alert>
    <modal-activate-shop :activate-callback="onStoreActivate" />
    <div v-if="error.length === 0">
      <div style="text-align: center; margin: 45vh 0" v-if="loading">
        <b-spinner label="Loading..." />
      </div>

      <b-row v-else>
        <b-col cols="12">
          <store-header :store-data="storeData" :activate-callback="onStoreActivate" />
        </b-col>
        <b-col cols="12" md="4">
          <ordenes-pendientes :callback="getOrdersData" :orders-data="pendingOrders" />
        </b-col>
        <b-col cols="12" md="4">
          <ordenes-en-preparacion :callback="getOrdersData" :orders-data="inPreparationOrders" />
        </b-col>
        <b-col cols="12" md="4">
          <ordenes-preparadas :orders-data="preparedOrders" />
        </b-col>
      </b-row>
    </div>

    <b-modal id="modal-alert" ref="modal-alert-ref" @ok="setAlertView" :title="modalAlertTitle" centered
      ok-title="Cerrar" no-close-on-backdrop ok-only>
      <div style="display: flex; justify-content: center;" class="mb-2">
        <b-avatar rounded :variant="modalAlertVariant" size="70">
          <feather-icon :icon="modalAlertIcon" size="65" />
        </b-avatar>
      </div>
      <h4 style="text-align: center">{{ modalAlertType }}</h4>
      <h4 style="text-align: center" class="mt-1">{{ modalAlertBody }}</h4>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BAvatar,
  BSpinner,
  BCardText,
  BCardHeader,
} from "bootstrap-vue";
import moment from "moment";
import StoreHeader from "./components/StoreHeader.vue";
import OrdenesPendientes from "./components/OrdenesPendientes.vue";
import OrdenesPreparadas from "./components/OrdenesPreparadas.vue";
import OrdenesEnPreparacion from "./components/OrdenesEnPreparacion.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalActivateShop from "../components/views-rol/ModalActivateShop.vue";
import { getAuthToken, getDefaultStore } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BAvatar,
    BSpinner,
    BCardText,
    StoreHeader,
    BCardHeader,
    OrdenesPendientes,
    OrdenesPreparadas,
    OrdenesEnPreparacion,
    ToastificationContent,
    ModalActivateShop
  },
  data() {
    return {
      error: "",
      alert: null,
      loading: true,
      storeData: null,
      pendingOrders: [],
      preparedOrders: [],
      intervalOrders: null,
      intervalAlerts: null,
      inPreparationOrders: [],
      updateMilliseconds: 120000,
    };
  },
  mounted() {
    this.getOrdersData().then(() => {
      this.listenSocketOrders();
      this.listenSocketAlerts();
      this.listenStoreSelectedChanges();
    });

    /*  
    No es recomendable uso de intervalos para consultar ordenes
    TODO: Usar el socket

    this.intervalOrders = setInterval(
         () => {
             this.getOrdersData(false);
         }
         ,
         this.updateMilliseconds
     ); */
  },
  beforeDestroy() {
    this.unwatchSocketOrder && this.unwatchSocketOrder();
    this.unwatchStoreSelect && this.unwatchStoreSelect();
    this.unwatchSocketAlert && this.unwatchSocketAlert();
    if (this.intervalAlerts != null) {
      clearInterval(this.intervalAlerts);
    }
  },
  destroyed() {
    if (this.intervalOrders != null) {
      clearInterval(this.intervalOrders);
    }
  },
  methods: {
    onNewOrder(order) {
      this.clearExistingOrder(order);
      switch (order.estadoorden) {
        case "PG":
          this.pendingOrders.unshift(order);
          break;
        case "EP":
          this.inPreparationOrders.unshift(order);
          break;
        case "PR":
          this.preparedOrders.unshift(order);
          break;
        case "AN":
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: `Orden #${order.idorden} fue anulada`,
              title: "Alerta de Anulación",
              variant: "warning",
            },
          });
          break;
        default:
          break;
      }
    },
    clearExistingOrder(order) {
      let index = -1;
      index = this.pendingOrders.findIndex((o) => o.idorden === order.idorden);
      if (index > -1) this.pendingOrders.splice(index, 1);
      index = this.preparedOrders.findIndex((o) => o.idorden === order.idorden);
      if (index > -1) this.preparedOrders.splice(index, 1);
      index = this.inPreparationOrders.findIndex((o) => o.idorden === order.idorden);
      if (index > -1) this.inPreparationOrders.splice(index, 1);
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("HH:mm a");
    },
    onOrderClick(params) {
      this.$router.push({
        name: "orden-detalle",
        params: { id: params.row.idorden, orden: params.row },
      });
    },
    onStoreActivate(store) {
      this.storeData.activo = true;
    },
    getOrdersData(setLoading = true) {
      if (setLoading) this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/OrdenesKanban",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (setLoading) this.loading = false;
            if (res.data.bOk) {
              const orders = res.data.lstOrdenes;
              this.storeData = JSON.parse(res.data.trace);
              this.pendingOrders = orders.filter((o) => o.estadoorden === "PG");
              this.preparedOrders = orders.filter(
                (o) => o.estadoorden === "PR"
              );
              this.inPreparationOrders = orders.filter(
                (o) => o.estadoorden === "EP"
              );
              resolve();
            } else {
              this.error = res.data.mensaje;
              reject();
            }
          })
          .catch((e) => {
            if (setLoading) this.loading = false;
            this.error = e.message;
            reject();
          });
      });
    },
    getAlerts() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CallCenterNotificacion",
          body: JSON.stringify({
            Visto: false,
            tokenSesion: getAuthToken(),
            IDEmpresaAsociada: getDefaultStore(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            if (res.data.notificaciones.length > 0) {
              this.alert = res.data.notificaciones[0];
              this.$refs["modal-alert-ref"].show();
            } else {
              this.alert = null;
            }
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          console.log('ERROR ALERTS: ', e);
        });
    },
    setAlertView() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CallCenterNotificacionMarcarComoVista",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
            NotificacionID: this.alert.notificacionID,
          }),
        }).then((res) => {
          if (res.data.bOk) {
            this.alert = null;
          } else {
            throw new Error(res.data.mensaje);
          }
        }).catch((e) => {
          console.log('ERROR ALERTS: ', e);
        });
    },
    listenSocketOrders() {
      this.unwatchSocketOrder = this.$store.watch(
        (_, g) => {
          return g["app/socketOrder"];
        },
        (order) => {
          this.onNewOrder(order);
        }
      );
    },
    listenSocketAlerts() {
      /* 
      No es recomendable uso de intervalos para consultar alertas
      TODO: Usar el socket
      this.unwatchSocketAlert = this.$store.watch(
        (_, g) => {
          return g["app/socketAlert"];
        },
        (alert) => {
          this.onNewAlert(alert);
        }
      );
      */
      this.intervalAlerts = setInterval(() => this.getAlerts(), this.updateMilliseconds);
    },
    listenStoreSelectedChanges() {
      this.unwatchStoreSelect = this.$store.watch(
        (_, g) => {
          return g["app/storeSelected"];
        },
        (_, __) => {
          this.getOrdersData();
        }
      );
    },
  },
  computed: {
    modalAlertTitle() {
      if (this.alert != null) return this.alert.titulo;
      return '';
    },
    modalAlertBody() {
      if (this.alert != null) return this.alert.mensaje;
      return '';
    },
    modalAlertType() {
      if (this.alert != null) {
        switch (this.alert.tipoAlerta) {
          case "PR":
            return "Tienes ordenes sin preparar";
          case "NV":
            return "Tienes una orden pendiente";
          case "EB":
            return "Banner de emergencia";
          case "DT":
            return "Desactiva tu tienda";
          default:
            break;
        }
      }
      return '';
    },
    modalAlertIcon() {
      if (this.alert != null) {
        switch (this.alert.icono) {
          case "warning":
            return "AlertTriangleIcon";
          case "info":
            return "BellIcon";
          case "danger":
            return "RadioIcon";
          case "success":
            return "SmileIcon";
          default:
            break;
        }
      }
      return '';
    },
    modalAlertVariant() {
      if (this.alert != null) {
        switch (this.alert.icono) {
          case "warning":
            return "light-warning";
          case "info":
            return "light-info";
          case "danger":
            return "light-danger";
          case "success":
            return "light-primary";
          default:
            break;
        }
      }
      return '';
    },
  },
};
</script>
