<template>
    <div>
      <div class="ldng" v-if="loading">
        <b-spinner />
      </div>
      <form-wizard
        v-else
        color="#04a82c"
        class="wizard-vertical"
        layout="vertical"
        :title="null"
        :subtitle="null"
        step-size="xs"
        @on-complete="formSubmitted"
        back-button-text="Volver"
        next-button-text="Siguiente"
        finish-button-text="Actualizar Factura"
      >
        <!-- numero de factura tab -->
        <tab-content title="Factura">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Ingrese el número de factura</h5>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-input
                id="facturaIn1"
                type="text"
                placeholder="000"
                v-model="factura1"
                :formatter="(s) => formatter(s, 3)"
              />
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-input
                id="facturaIn2"
                type="text"
                placeholder="000"
                v-model="factura2"
                :formatter="(s) => formatter(s, 3)"
              />
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-input
                id="facturaIn3"
                type="text"
                placeholder="000000000"
                v-model="factura3"
                :formatter="(s) => formatter(s, 9)"
              />
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput
  } from "bootstrap-vue";
  import { getAuthToken } from "@/auth/utils";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import { FormWizard, TabContent } from "vue-form-wizard";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BSpinner,
      FormWizard,
      TabContent,
      BFormGroup,
      BFormInput,
      ToastificationContent
    },
    props: {
        ordenId: {
          type: Number,
          required: true,
        },
        factura: {
          type: String,
          default: "",
        }
    },
    data() {
      return {
        tiempo: 10,
        loading: false,
        factura1: "000",
        factura2: "000",
        factura3: "000000000",
      };
    },
    mounted() {
      if ( this.factura && this.factura.length > 0 ) {
        const arrFactura =  this.factura.split('-')
        this.factura1 = arrFactura[0]
        this.factura2 = arrFactura[1]
        this.factura3 = arrFactura[2]
      }
    },
    methods: {
      formatter(value, length) {
        const regExp = /[a-zA-Z]/g;
        value = value.replace(regExp, "");
        if (value.length > length) return value.substring(1);
        else if (value.length === length) return value;
        else return "0" + value;
      },
      formSubmitted() {
        const factura = `${this.factura1}-${this.factura2}-${this.factura3}`;
        this.loading = true
        this.$http
            .post(this.$store.state.app.middlewareURL, {
            path: "/Customer/ActualizarFactura",
            body: JSON.stringify({
                IDOrden: this.ordenId,
                factura: factura,
                tokenSesion: getAuthToken(),
            }),
            })
            .then((res) => {
                if (res.data.bOk) {
                    this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Exito",
                        icon: "CheckIcon",
                        text: "Se cambio correctamente la factura.",
                        variant: "success",
                    },
                    });
            } else {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Error",
                    icon: "AlertTriangleIcon",
                    text: `Ocurrio un error al cambiar la factura(${res.data.mensaje}).`,
                    variant: "danger",
                },
                });
            }
            this.loading = false
            })
            .catch((e) => {
            this.$toast({
                component: ToastificationContent,
                props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al cambiar la factura(${e.message}).`,
                variant: "danger",
                },
            });
            this.loading = false;
            });
      },
    },
  };
  </script>
  <style lang="scss">
  .vue-form-wizard {
    box-shadow: none !important;
  }
  .ldng {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em;
  }
  @import "@core/scss/vue/libs/vue-wizard.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  