<template>
    <b-card-group deck>
        <b-card>
            <b-media no-body>
                <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="34">
                        <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                </b-media-aside>
                <b-media-body>
                    <h6 class="mb-0">
                        Fecha de creación
                    </h6>
                    <small> {{ formattedDate(ordenData.fechacreacion) }} - {{ formattedHour(ordenData.fechacreacion)
                        }}</small>
                </b-media-body>
            </b-media>

            <b-media class="mt-1" no-body>
                <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="34">
                        <feather-icon icon="SendIcon" size="18" />
                    </b-avatar>
                </b-media-aside>
                <b-media-body>
                    <h6 class="mb-0">
                        Tipo de entrega
                    </h6>
                    <small>
                        <b-badge v-if="ordenData.tipoentrega === 'PG'" variant="light-warning">
                            <small style="font-weight: bold">
                                Programada
                            </small>
                        </b-badge>
                        <b-badge v-else-if="ordenData.tipoentrega === 'SA'" variant="light-info">
                            <small style="font-weight: bold">
                                Sin Apuros
                            </small>
                        </b-badge>
                        <b-badge v-else variant="light-success">
                            <small style="font-weight: bold">
                                Inmediata
                            </small>
                        </b-badge>

                        <b-badge v-if="ordenData.tipoentrega === 'PG'" class="ml-1">
                            <small>
                                {{ formattedDate(ordenData.fechaprogramada) }}
                                {{ formattedHour(ordenData.fechaprogramada) }}
                            </small>
                        </b-badge>

                    </small>
                </b-media-body>
            </b-media>

            <b-media v-if="ordenData.prTipo != null" class="mt-1" no-body>
                <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="34">
                        <feather-icon icon="GiftIcon" size="18" />
                    </b-avatar>
                </b-media-aside>
                <b-media-body>
                    <h6 class="mb-0">
                        Promoción ({{ getPromoType }})
                    </h6>
                    <small> {{ ordenData.prNombre }} </small>
                </b-media-body>
            </b-media>

        </b-card>

    </b-card-group>
</template>

<script>
import {
    BCard,
    BBadge,
    BMedia,
    BAvatar,
    BButton,
    BCardGroup,
    BMediaBody,
    BMediaAside,
} from "bootstrap-vue";
import moment from "moment";

export default {
    components: {
        BCard,
        BBadge,
        BMedia,
        BButton,
        BAvatar,
        BCardGroup,
        BMediaBody,
        BMediaAside,
    },
    props: {
        ordenData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        formattedDate(originalDate) {
            return moment(new Date(originalDate)).format("DD/MM/YYYY");
        },
        formattedHour(originalDate) {
            return moment(new Date(originalDate)).format("hh:mm a");
        },
    },
    computed: {
        getPromoType() {
            switch (this.ordenData.prTipo) {
                case "PROCO":
                    return "Promocode";
                case "PROTA":
                    return "Promotarjeta";
                default:
                    return "Promotarjeta";
            }
        },
    },
};
</script>

<style></style>