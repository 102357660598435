var render = function () {
  var _vm$storeData$RazonCo, _vm$storeData$Ruc;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-group', {
    staticClass: "mb-2",
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "profile-img-container d-flex align-items-center"
  }, [_c('div', {
    staticClass: "profile-img"
  }, [_c('b-img', {
    staticStyle: {
      "max-width": "100px",
      "max-height": "80px"
    },
    attrs: {
      "src": _vm.urlLogo,
      "rounded": "",
      "fluid": "",
      "alt": "profile photo"
    }
  })], 1), _c('div', {
    staticClass: "profile-title px-2 pr-auto"
  }, [_c('h2', [_vm._v(" " + _vm._s((_vm$storeData$RazonCo = _vm.storeData.RazonComercial) !== null && _vm$storeData$RazonCo !== void 0 ? _vm$storeData$RazonCo : _vm.storeData.razonComercial) + " ")]), _c('p', [_c('b', [_vm._v("RUC:")]), _vm._v(" " + _vm._s((_vm$storeData$Ruc = _vm.storeData.Ruc) !== null && _vm$storeData$Ruc !== void 0 ? _vm$storeData$Ruc : _vm.storeData.ruc))])])])])]), _c('b-card', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "std mb-1"
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v("Estado de la tienda")]), _vm.storeData.activo == 1 ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v("Activa")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v("Desactivada")])], 1), _c('div', {
    staticClass: "std"
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v("Nivel de Demanda")]), _vm.storeData.altaDemanda ? _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "pill": "",
      "variant": "light-warning"
    }
  }, [_vm._v("Alta Demanda")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v("Normal")])], 1), _vm.storeData.activo == 0 ? _c('b-button', {
    staticClass: "mt-75",
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.showMsgEstado
    }
  }, [_vm.loadingActivate ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v(" Activar Tienda ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }