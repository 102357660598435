var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "datos-cliente",
    attrs: {
      "title": "Datos del Gacela"
    }
  }, [_vm.ordenData.lstRepartidor.length > 0 ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "profile-img text-center mb-3 border p-2 rounded-lg"
  }, [_c('img', {
    staticClass: "img-fluid rounded",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "src": _vm.ordenData.lstRepartidor[0].fotorepartidor,
      "alt": _vm.ordenData.lstRepartidor[0].nombrecompleto
    }
  })])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Nombre")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.lstRepartidor[0].nombrecompleto) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CreditCardIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Placa")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.lstRepartidor[0].placarepartidor) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PhoneIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Teléfono")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.lstRepartidor[0].telefonorepartidor) + " ")])])], 1)], 1)], 1)], 1)], 1) : _c('b-alert', {
    staticClass: "m-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertCircleIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("No hay repartidor.")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }