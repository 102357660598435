var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" Fecha de creación ")]), _c('small', [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.ordenData.fechacreacion)) + " - " + _vm._s(_vm.formattedHour(_vm.ordenData.fechacreacion)))])])], 1), _c('b-media', {
    staticClass: "mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SendIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" Tipo de entrega ")]), _c('small', [_vm.ordenData.tipoentrega === 'PG' ? _c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_c('small', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Programada ")])]) : _vm.ordenData.tipoentrega === 'SA' ? _c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_c('small', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Sin Apuros ")])]) : _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_c('small', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Inmediata ")])]), _vm.ordenData.tipoentrega === 'PG' ? _c('b-badge', {
    staticClass: "ml-1"
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.ordenData.fechaprogramada)) + " " + _vm._s(_vm.formattedHour(_vm.ordenData.fechaprogramada)) + " ")])]) : _vm._e()], 1)])], 1), _vm.ordenData.prTipo != null ? _c('b-media', {
    staticClass: "mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "GiftIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" Promoción (" + _vm._s(_vm.getPromoType) + ") ")]), _c('small', [_vm._v(" " + _vm._s(_vm.ordenData.prNombre) + " ")])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }