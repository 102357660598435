<template>
  <b-card title="Datos del Cliente" class="datos-cliente">
    <b-row>

      <b-col v-if="hasTags" cols="12">

        <div :class="['rounded', 'mb-1', tagVariant(ordenData.cliente[0].etiqueta)]">
          <h4 class="p-1">
            {{ getTags }}
          </h4>
        </div>

      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="UserIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Nombre</small>
            <p class="mhj">
              {{ getNombre }}
              {{ getApellido }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="CreditCardIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Cédula/RUC</small>
            <p class="mhj">
              {{ getTipoIdentificacion }} -
              {{ getIdentificacion }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="MapIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Dirección</small>
            <p class="mhj">
              {{ getDireccion }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="FlagIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Referencia</small>
            <p class="mhj">
              {{ getDireccionReferencia }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="PhoneIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Teléfono</small>
            <p class="mhj">
              {{ getTelefono }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMedia,
  BBadge,
  BButton,
  BAvatar,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BMediaBody,
    BMediaAside,
  },
  props: {
    ordenData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rappiClientOrder: {}
    }
  },
  created() {
    // Origen 3 - órdenes de Rappi
    if (this.ordenData.origen !== 3) return;

    this.$http
      .post(this.$store.state.app.middlewareURL, {
        path: "/Customer/rappi_client_order",
        body: JSON.stringify({
          tokenSesion: getAuthToken(),
          orderId: this.ordenData.idorden,
        }),
      })
      .then((res) => {
        if (res.data.bOk) {
          this.rappiClientOrder = res.data.data;
        } else {
          throw new Error(res.data.mensaje);
        }
      })
      .catch((e) => {
        this.$toast({
          props: {
            icon: "AlertTriangleIcon",
            text: `Ocurrió un error [${e.message}].`,
            title: "Error",
            variant: "danger",
          },
          component: ToastificationContent,
        });
      })
  },
  computed: {
    hasTags() {
      if (this.ordenData.origen === 3) {
        return false;
      }
      if (this.ordenData.cliente.length > 0) {
        if (this.ordenData.cliente[0].etiqueta)
          return this.ordenData.cliente[0].etiqueta.length > 0;
      }
      return false;
    },
    getTags() {
      switch (this.ordenData.cliente[0].etiqueta) {
        case "PRB":
          return "Problemático";
        case "FRD":
          return "Fraudulento";
        case "VIP":
          return "V.I.P.";
        default:
          break;
      }
      return;
    },
    tagVariant() {
      const tagColor = {
        VIP: "bg-light-success",
        FRD: "bg-light-danger",
        PRB: "bg-light-warning",
      };
      return (s) => tagColor[s];
    },
    getDireccion() {
      if (this.ordenData.origen === 3) {
        return ''
      }
      return this.ordenData.lstDireccionEntrega.length > 0 ? this.ordenData.lstDireccionEntrega[0].direccion : '';
    },
    getDireccionReferencia() {
      if (this.ordenData.origen === 3) {
        return ''
      }
      return this.ordenData.lstDireccionEntrega.length > 0 ? this.ordenData.lstDireccionEntrega[0].detalle : '';
    },
    getNombre() {
      if (this.ordenData.origen === 3) {
        return this.rappiClientOrder.firstName;
      }
      return this.ordenData.cliente.length > 0 ? this.ordenData.cliente[0].nombre : '';
    },
    getApellido() {
      if (this.ordenData.origen === 3) {
        return this.rappiClientOrder.lastName;
      }
      return this.ordenData.cliente.length > 0 ? this.ordenData.cliente[0].apellido : '';
    },
    getTipoIdentificacion() {
      if (this.ordenData.origen === 3) {
        return '';
      }
      return this.ordenData.cliente.length > 0 ? this.ordenData.cliente[0].tipoidentifica : '';
    },
    getIdentificacion() {
      if (this.ordenData.origen === 3) {
        return this.rappiClientOrder.identification;
      }
      return this.ordenData.cliente.length > 0 ? this.ordenData.cliente[0].identificacion : '';
    },
    getTelefono() {
      if (this.ordenData.origen === 3) {
        return this.rappiClientOrder.phone;
      }
      return this.ordenData.cliente.length > 0 ? this.ordenData.cliente[0].telefono : '';
    }
  },
};
</script>

<style>
.mhj {
  margin: 0;
  font-size: 12px;
  line-height: 14px !important;
  font-weight: bold;
}

.datos-cliente .media {
  margin-bottom: 8px;
}
</style>
