<template>
  <b-card title="Datos del Gacela" class="datos-cliente">
    <b-row v-if="ordenData.lstRepartidor.length > 0">
      <b-col cols="6">
        <div class="profile-img text-center mb-3 border p-2 rounded-lg">
          <img :src="ordenData.lstRepartidor[0].fotorepartidor" :alt="ordenData.lstRepartidor[0].nombrecompleto"
            class="img-fluid rounded" style="width: 150px;" />
        </div>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="12">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="UserIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Nombre</small>
                <p class="mhj">
                  {{ ordenData.lstRepartidor[0].nombrecompleto }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col cols="12">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="CreditCardIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Placa</small>
                <p class="mhj">
                  {{ ordenData.lstRepartidor[0].placarepartidor }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col cols="12">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="PhoneIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Teléfono</small>
                <p class="mhj">
                  {{ ordenData.lstRepartidor[0].telefonorepartidor }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-alert show v-else variant="warning" class="m-1">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="AlertCircleIcon" />
        <span class="ml-25">No hay repartidor.</span>
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BMedia,
  BBadge,
  BButton,
  BAvatar,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BMediaBody,
    BMediaAside,
  },
  props: {
    ordenData: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style>
.mhj {
  margin: 0;
  font-size: 12px;
  line-height: 8px;
  font-weight: bold;
}

.datos-cliente .media {
  margin-bottom: 8px;
}
</style>
