<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="meetup-header d-flex align-items-center mb-2">
          <div class="meetup-day">
            <h6 class="mb-0">CANT.</h6>
            <h3 class="mb-0">
              <b-badge variant="light-primary">
                {{ ordersData.length }}
              </b-badge>
            </h3>
          </div>
          <div class="my-auto">
            <b-card-text class="mb-0"> Ordenes </b-card-text>
            <b-card-title class="mb-25"> En Preparación </b-card-title>
          </div>
        </div>

        <!-- media -->
        <div class="media-bordered op">
          <b-media :key="order.idorden" v-for="order in ordersData" class="py-1" no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <countdown :time="calcPrepEndTime(order)" v-slot="{ minutes }">
                  <div style="display: flex">
                    <radial-progress-bar :diameter="35" :stopColor="`#04a82c`" :startColor="`#04a82c`" :strokeWidth="3"
                      :total-steps="calcPrepMinutes(order)" :animateSpeed="800" :completed-steps="minutes"
                      :innerStrokeWidth="4" :innerStrokeColor="`#E5E5E5`">
                      {{ minutes }}
                    </radial-progress-bar>
                  </div>
                </countdown>
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <div class="cntr">
                <div>
                  <h6 @click="onOrderClick(order)" role="button" class="mb-0">
                    #{{ order.idorden }}
                    <b-badge :variant="order.tipoentrega === 'IM' ? 'light-primary' : 'light-warning'">
                      <feather-icon :icon="order.tipoentrega === 'IM' ? 'ClockIcon' : 'CalendarIcon'
                        " size="18" class="mr-25" />
                      <small>{{ order.tipoentrega === 'IM' ? 'Inmediata' : 'Programada' }}</small>
                    </b-badge>
                  </h6>
                  <small>{{ order.cliente[0]?.nombreCompleto ?? 'Error: Orden sin cliente' }}</small>
                  <p v-if="order.fechaprogramada !== null" style="font-weight: bold;font-size: 10px;" class="mb-25">
                    Fecha programada: {{ formattedDate(order.fechaprogramada) }}
                  </p>
                  <p style="font-weight: bold;font-size: 10px;" class="m-0">
                    Fecha creación: {{ formattedDateCreated(order.fechacreacion) }}
                  </p>
                </div>

              </div>
              <b-button variant="outline-primary" class="btn-icon" @click="onClickPreparar(order)"
                :disabled="order.loading" v-b-tooltip.hover.top="'Marcar listo'">
                <b-spinner v-if="order.loading" small label="Loading..." />
                <feather-icon v-else icon="CheckCircleIcon" class="mr-25" />
                Marcar preparada
              </b-button>
            </b-media-body>
          </b-media>
        </div>
      </b-card-body>
    </b-card>
    <!-- modal orden -->
    <b-modal id="modal-orden" @ok="onClickPreparar(ordenData)" size="xl" :title="'Pedido #' + ordenData.idorden"
      v-model="showModal" @cancel="onClickCancelar" centered ok-title="Marcar como listo" scrollable
      cancel-title="Editar factura">
      <b-row>
        <b-col cols="12" md="5">
          <b-row>
            <b-col cols="12">
              <orden-card :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <cliente-card :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <gacela-card v-if="ordenData.lstRepartidor" :orden-data="ordenData" />
            </b-col>
            <b-col cols="12">
              <detalles-card :orden-data="ordenData" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="7">
          <productos-card :productos-data="ordenData.lstProductos" :order-id="ordenData.idorden"
            :driver-states="ordenData.lstEstadosMotorizado" :order-state="ordenData.estadoorden"
            :commerce-type="ordenData.lineadenegocio" />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-factura" size="lg" title='Actualizar factura' v-model="showModalPreparar" centered hide-footer>
      <factura-modal :ordenId="ordenData.idorden"
        :factura="ordenData.lstFacturaPago && ordenData.lstFacturaPago[0].factura_empresa_asociada" />
    </b-modal>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BMedia,
  BAvatar,
  BButton,
  BSpinner,
  BCardBody,
  BCardText,
  VBTooltip,
  BMediaBody,
  BCardTitle,
  BCardHeader,
  BMediaAside,
} from "bootstrap-vue";

import moment from "moment";
import Ripple from "vue-ripple-directive";
import OrdenCard from "./OrdenCard2.vue";
import Countdown from "@chenfengyuan/vue-countdown";
import ClienteCard from "./ClienteCard.vue";
import DetallesCard from "./DetallesCard.vue";
import ProductosCard from "./ProductosCard.vue";
import { getAuthToken } from "@/auth/utils";
import RadialProgressBar from "vue-radial-progress";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import GacelaCard from "./GacelaCard.vue";
import PreparacionModal from "./PreparacionModal.vue";
import FacturaModal from "./FacturaModal.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BMedia,
    BAvatar,
    BButton,
    BSpinner,
    BCardBody,
    BCardText,
    VBTooltip,
    Countdown,
    OrdenCard,
    BMediaBody,
    BCardTitle,
    BCardHeader,
    BMediaAside,
    ClienteCard,
    DetallesCard,
    ProductosCard,
    GacelaCard,
    RadialProgressBar,
    VuePerfectScrollbar,
    ToastificationContent,
    PreparacionModal,
    FacturaModal
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    ordersData: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      showModalPreparar: false,
      ordenData: {
        idorden: 0,
      },
      perfectScrollbarSettings: { maxScrollbarLength: 150 },
    };
  },
  methods: {
    formattedDateCreated(originalDate) {
      const dateFormatted = moment(originalDate).format("DD/MM/YYYY hh:mm a");
      return dateFormatted;
    },
    onClickCancelar(e) {
      e && e.preventDefault();
      this.showModal = false;
      setTimeout(() => {
        this.showModalPreparar = true;
        console.log(this.showModalPreparar)
      }, 100);

    },
    hidePreparacionModal() {
      this.showModalPreparar = false;
    },
    onOrderClick(order) {
      this.ordenData = order;
      this.showModal = true;
    },
    calcPrepEndTime(orden) {
      const ordenEnPreparacion = orden.lstEstadosOrden.filter(oe => oe.codigo === 'EP');
      const size = ordenEnPreparacion.length - 1;
      const endTime =
        moment(ordenEnPreparacion[size].fecha)
          .add(this.calcPrepMinutes(orden), "minutes");
      const minutesRemain = moment.duration(endTime.diff(new Date(this.currentDate()))).asMinutes();
      if (minutesRemain > 0) {
        const time = minutesRemain * 60 * 1000
        setTimeout(() => {
          this.preparar(orden.idorden)
        }, time)
        return time
      }
      return minutesRemain > 0 ? minutesRemain * 60 * 1000 : 0;
    },
    currentDate() {
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0'); // Agregar ceros a la izquierda si es necesario
      const dia = fechaActual.getDate().toString().padStart(2, '0');
      const hora = fechaActual.toLocaleString('en-US', { hour: 'numeric', hour12: true }).toString().replace(/\s[APM]{2}$/, '').padStart(2, '0'); // Eliminar AM o PM
      const minutos = fechaActual.getMinutes().toString().padStart(2, '0');
      const segundos = fechaActual.getSeconds().toString().padStart(2, '0');

      const fechaHoraEnFormato = `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
      return fechaHoraEnFormato;
    },
    calcPrepMinutes(orden) {
      return orden.tiempopreparacion ? parseInt(orden.tiempopreparacion) : 0;
    },
    formattedDate(originalDate) {
      if (originalDate === null) return "";
      const dateFormatted = moment(originalDate).format("DD/MM/YYYY hh:mm a");
      return `[${dateFormatted}]`;
    },
    onClickPreparar(order) {
      this.$bvModal
        .msgBoxConfirm(
          `¿Está seguro que desea marcar el pedido[#${order.idorden}] como listo?`,
          {
            size: "sm",
            title: "Confirmar",
            okTitle: "Sí, el pedido está listo",
            centered: true,
            okVariant: "primary",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
          }
        )
        .then((value) => {
          if (value) {
            this.preparar(order.idorden);
          }
        });
    },
    preparar(orderID) {
      const index = this.ordersData.findIndex((o) => o.idorden === orderID);
      if (index > -1) {
        this.ordersData[index].loading = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/PrepararOrden",
            body: JSON.stringify({
              IDOrden: orderID,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.callback();
              this.$toast({
                component: ToastificationContent,
                props: {
                  text: "Se preparo correctamente la orden.",
                  icon: "CheckIcon",
                  title: "Exito",
                  variant: "success",
                },
              });
            } else {
              this.ordersData[index].loading = true;
              this.$toast({
                component: ToastificationContent,
                props: {
                  text: `Ocurrio un error al preparar la orden. ${res.data.mensaje}`,
                  icon: "AlertTriangleIcon",
                  title: "Error",
                  variant: "danger",
                },
              });
            }
          })
          .catch((e) => {
            this.ordersData[index].loading = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                text: `Ocurrio un error al preparar la orden. ${e.message}`,
                icon: "AlertTriangleIcon",
                title: "Error",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

.cntr {
  display: flex;
  justify-content: space-between;
}

.meetup-header .meetup-day {
  display: flex;
  border-right: 1px solid #d8d6de;
  padding-right: 1.3rem;
  margin-right: 1.3rem;
  flex-direction: column;
  align-items: center;
}

.cp {
  cursor: pointer;
}

.radial-progress-inner {
  font-weight: bold;
}

#modal-orden .modal-body {
  max-height: 82vh;
}

#modal-orden .modal-dialog {
  max-width: 100vw;
}
</style>
