var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('div', {
    staticClass: "ldng"
  }, [_c('b-spinner')], 1) : _c('form-wizard', {
    staticClass: "wizard-vertical",
    attrs: {
      "color": "#04a82c",
      "layout": "vertical",
      "title": null,
      "subtitle": null,
      "step-size": "xs",
      "back-button-text": "Volver",
      "next-button-text": "Siguiente",
      "finish-button-text": "Actualizar Factura"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Factura"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Ingrese el número de factura")])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn1",
      "type": "text",
      "placeholder": "000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 3);
      }
    },
    model: {
      value: _vm.factura1,
      callback: function callback($$v) {
        _vm.factura1 = $$v;
      },
      expression: "factura1"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn2",
      "type": "text",
      "placeholder": "000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 3);
      }
    },
    model: {
      value: _vm.factura2,
      callback: function callback($$v) {
        _vm.factura2 = $$v;
      },
      expression: "factura2"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn3",
      "type": "text",
      "placeholder": "000000000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 9);
      }
    },
    model: {
      value: _vm.factura3,
      callback: function callback($$v) {
        _vm.factura3 = $$v;
      },
      expression: "factura3"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }