<template>
  <div class="checkout-options">
    <b-card title="Detalles de pago">
      <div class="price-details">
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title">SubTotal</div>
            <div class="detail-amt">${{ ordenData.subtotal.toFixed(2) }}</div>
          </li>
          <li
            v-if="userData.rol === 'SA' || userData.rol === 'CC'"
            class="price-detail"
          >
            <div class="detail-title">Descuento</div>
            <div class="detail-amt">${{ ordenData.descuento.toFixed(2) }}</div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              IVA
              <feather-icon id="tooltip-iva" icon="InfoIcon" size="20" class="mr-0 mr-sm-50" v-if="this.ordenData.aplica_iva_feriado" />
            </div>
            <div class="detail-amt">${{ ordenData.iva.toFixed(2) }}</div>
          </li>
          <li
            v-if="userData.rol === 'SA' || userData.rol === 'CC'"
            class="price-detail"
          >
            <div class="detail-title detail-total">Envío</div>
            <div class="detail-amt">
              ${{ ordenData.valor_envio.toFixed(2) }}
            </div>
          </li>
        </ul>
        <hr />
        <ul class="list-unstyled">
          <li
            v-if="userData.rol === 'SA' || userData.rol === 'CC'"
            class="price-detail"
          >
            <div class="detail-title detail-total">Total</div>
            <div class="detail-amt font-weight-bolder">
              ${{ ordenData.total.toFixed(2) }}
            </div>
          </li>
          <li v-if="userData.rol !== 'SA' && userData.rol !== 'CC'" class="price-detail">
            <div class="detail-title detail-total">Total</div>
            <div class="detail-amt font-weight-bolder">
              ${{ (ordenData.subtotal + ordenData.iva).toFixed(2) }}
            </div>
          </li>
        </ul>
        <b-alert variant="primary" :show="this.ordenData.aplica_iva_feriado">
          <em
            >Si posees Registro Tur&iacute;stico, este valor de IVA ser&aacute;
            diferente en tu factura.</em
          >
        </b-alert>
        <b-tooltip target="tooltip-iva" triggers="hover">
          Tu debes facturar conforme la Ley lo establece para tu caso. Si
          aplicas al beneficio del 8% de IVA y no te cuadra el valor del IVA con
          el presentado aquí, no te preocupes. Factura normalmente y no olvides
          incluir el número de tu documento en el campo INGRESAR FACTURA
        </b-tooltip>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getUserData } from "@/auth/utils";
import { BCard, BTooltip, BAlert } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTooltip,
    BAlert,
  },
  props: {
    ordenData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: getUserData(),
    };
  },
  methods: {
    computedTotal(lstProductos) {
      return (
        this.computedSubtotal(lstProductos) + this.computedIva(lstProductos)
      );
    },
    computedIva(lstProductos) {
      var subtotal = this.computedSubtotal(lstProductos);
      return subtotal * parseFloat(process.env.VUE_APP_VALOR_IVA);
    },
    computedSubtotal(lstProductos) {
      var subtotal = 0;
      lstProductos.forEach((product) => {
        subtotal += product.subtotal;
        if (product.lstModificadores.length > 0) {
          product.lstModificadores.forEach((mod) => {
            mod.items.forEach((item) => {
              subtotal += item.precio;
            });
          });
        }
      });
      return subtotal;
    },
  },
};
</script>
<style>
.price-details .price-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}
</style>
