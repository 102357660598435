<template>
  <b-card-group deck class="mb-2">
    <b-card>
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img :src="urlLogo" rounded fluid alt="profile photo" style="max-width: 100px; max-height: 80px" />
          </div>
          <div class="profile-title px-2 pr-auto">
            <h2>
              {{ storeData.RazonComercial ?? storeData.razonComercial }}
            </h2>
            <p><b>RUC:</b> {{ storeData.Ruc ?? storeData.ruc }}</p>
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="flex">
      <div class="std mb-1">
        <h5 class="m-0">Estado de la tienda</h5>
        <b-badge v-if="storeData.activo == 1" pill variant="light-primary">Activa</b-badge>
        <b-badge v-else pill variant="light-danger">Desactivada</b-badge>
      </div>

      <div class="std">
        <h5 class="m-0">Nivel de Demanda</h5>
        <b-badge v-if="storeData.altaDemanda" pill variant="light-warning" class="ml-2">Alta Demanda</b-badge>
        <b-badge v-else pill variant="light-primary">Normal</b-badge>
      </div>

      <b-button v-if="storeData.activo == 0" class="mt-75" block variant="primary" @click="showMsgEstado">
        <b-spinner v-if="loadingActivate" small label="Loading..." />
        <div v-else>
          Activar Tienda
        </div>
      </b-button>

    </b-card>
  </b-card-group>
</template>

<script>
import {
  BTab,
  BRow,
  BCol,
  BImg,
  BCard,
  BTabs,
  BBadge,
  BModal,
  BAspect,
  BNavbar,
  BButton,
  BSpinner,
  BNavItem,
  BCollapse,
  BCardGroup,
  BNavbarToggle,
} from "bootstrap-vue";
import {
  getUserID,
  getAuthToken,
} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BImg,
    BCard,
    BTabs,
    BBadge,
    BModal,
    BAspect,
    BNavbar,
    BButton,
    BSpinner,
    BNavItem,
    BCollapse,
    BCardGroup,
    BNavbarToggle,
  },
  props: {
    storeData: {
      type: Object,
      default: () => { },
    },
    activateCallback: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      loadingActivate: false,
    };
  },
  methods: {
    showMsgEstado() {
      this.$bvModal
        .msgBoxConfirm("Esta seguro que desea Activar la Tienda?", {
          size: "sm",
          title: "Activar Tienda",
          okTitle: "Activar",
          centered: true,
          okVariant: "primary",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.loadingActivate = true;
            this.$http
              .post(this.$store.state.app.middlewareURL, {
                path: "/Administracion/AdminEmpresaAsociada",
                body: JSON.stringify({
                  opt: "ADT",
                  empresa: 2,
                  usuario: `${getUserID()}`,
                  idAsociada: this.storeData.IDEmpresaAsociado ?? this.storeData.idEmpresaAsociado,
                  tokenSesion: getAuthToken(),
                }),
              })
              .then((res) => {
                if (res.data.bOk) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Éxito",
                      icon: "CheckIcon",
                      text: "Se cambio correctamente el estado de la tienda.",
                      variant: "success",
                    },
                  });
                  this.storeData.activo = 1;
                  this.activateCallback(this.storeData);
                } else {
                  throw new Error(res.data.mensaje);
                }
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "AlertTriangleIcon",
                    text: `Ocurrió un error al cambiar el estado de la tienda (${e.message}).`,
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.loadingActivate = false;
              });
          }
        });
    },
  },
  computed: {
    urlLogo() {
      try {
        const url = new URL(this.storeData.Logo ?? this.storeData.logo);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
  },
};
</script>
<style>
.std {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.std .badge {
  display: flex;
  align-items: center;
  font-size: 90%;
  padding: 5px 20px !important;
}
</style>