var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkout-options"
  }, [_c('b-card', {
    attrs: {
      "title": "Detalles de pago"
    }
  }, [_c('div', {
    staticClass: "price-details"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("SubTotal")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v("$" + _vm._s(_vm.ordenData.subtotal.toFixed(2)))])]), _vm.userData.rol === 'SA' || _vm.userData.rol === 'CC' ? _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("Descuento")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v("$" + _vm._s(_vm.ordenData.descuento.toFixed(2)))])]) : _vm._e(), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v(" IVA "), this.ordenData.aplica_iva_feriado ? _c('feather-icon', {
    staticClass: "mr-0 mr-sm-50",
    attrs: {
      "id": "tooltip-iva",
      "icon": "InfoIcon",
      "size": "20"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v("$" + _vm._s(_vm.ordenData.iva.toFixed(2)))])]), _vm.userData.rol === 'SA' || _vm.userData.rol === 'CC' ? _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title detail-total"
  }, [_vm._v("Envío")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v(" $" + _vm._s(_vm.ordenData.valor_envio.toFixed(2)) + " ")])]) : _vm._e()]), _c('hr'), _c('ul', {
    staticClass: "list-unstyled"
  }, [_vm.userData.rol === 'SA' || _vm.userData.rol === 'CC' ? _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title detail-total"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "detail-amt font-weight-bolder"
  }, [_vm._v(" $" + _vm._s(_vm.ordenData.total.toFixed(2)) + " ")])]) : _vm._e(), _vm.userData.rol !== 'SA' && _vm.userData.rol !== 'CC' ? _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title detail-total"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "detail-amt font-weight-bolder"
  }, [_vm._v(" $" + _vm._s((_vm.ordenData.subtotal + _vm.ordenData.iva).toFixed(2)) + " ")])]) : _vm._e()]), _c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": this.ordenData.aplica_iva_feriado
    }
  }, [_c('em', [_vm._v("Si posees Registro Turístico, este valor de IVA será diferente en tu factura.")])]), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-iva",
      "triggers": "hover"
    }
  }, [_vm._v(" Tu debes facturar conforme la Ley lo establece para tu caso. Si aplicas al beneficio del 8% de IVA y no te cuadra el valor del IVA con el presentado aquí, no te preocupes. Factura normalmente y no olvides incluir el número de tu documento en el campo INGRESAR FACTURA ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }