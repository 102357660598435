var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.error.length > 0
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Ocurrió un error al obtener las ordenes " + _vm._s(_vm.error) + " ")])]), _c('modal-activate-shop', {
    attrs: {
      "activate-callback": _vm.onStoreActivate
    }
  }), _vm.error.length === 0 ? _c('div', [_vm.loading ? _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "45vh 0"
    }
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('store-header', {
    attrs: {
      "store-data": _vm.storeData,
      "activate-callback": _vm.onStoreActivate
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ordenes-pendientes', {
    attrs: {
      "callback": _vm.getOrdersData,
      "orders-data": _vm.pendingOrders
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ordenes-en-preparacion', {
    attrs: {
      "callback": _vm.getOrdersData,
      "orders-data": _vm.inPreparationOrders
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ordenes-preparadas', {
    attrs: {
      "orders-data": _vm.preparedOrders
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-modal', {
    ref: "modal-alert-ref",
    attrs: {
      "id": "modal-alert",
      "title": _vm.modalAlertTitle,
      "centered": "",
      "ok-title": "Cerrar",
      "no-close-on-backdrop": "",
      "ok-only": ""
    },
    on: {
      "ok": _vm.setAlertView
    }
  }, [_c('div', {
    staticClass: "mb-2",
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": _vm.modalAlertVariant,
      "size": "70"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.modalAlertIcon,
      "size": "65"
    }
  })], 1)], 1), _c('h4', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.modalAlertType))]), _c('h4', {
    staticClass: "mt-1",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.modalAlertBody))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }