<template>
  <b-modal
    id="modal-danger"
    @ok="cancelarPedido"
    @hide="$emit('hide')"
    :title="title"
    v-model="mShow"
    centered
    hide-footer
    modal-class="modal-danger"
  >
    <div class="ldng" v-if="loading">
      <h5>Cancelando orden...</h5>
      <b-spinner />
    </div>
    <div v-else>
      <b-card-text>
        Es posible que esto genere molestias con el cliente y debe ser utilizado
        como último recurso. Si presentas alguna novedad con la orden y no
        podrás despacharla, puedes cancelarla. Por favor, realizar esto en caso
        de ser necesario y estar seguro de querer cancelar la orden.
      </b-card-text>
      <b-button variant="danger" @click="cancelarPedido"
        >Si, CANCELAR el pedido</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import { BRow, BCol, BCard, BButton, BSpinner, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserID, getAuthToken } from "@/auth/utils";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BCardText,
  },
  props: {
    ordenId: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  watch: {
    show: function (newVal) {
      if (newVal) {
        this.mShow = true;
      }
    },
  },
  data() {
    return {
      mShow: false,
      loading: false,
    };
  },
  methods: {
    cancelarPedido(event) {
      event.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/ActualizaEstadoOrden",
          body: JSON.stringify({
            opt: "CAN",
            empresa: 2,
            idOrden: this.ordenId,
            usuario: getUserID().toString(),
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se cancelo correctamente el pedido`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
            this.callback();
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al cancelar el pedido [${e.message}].`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    title() {
      return `¿Está seguro de cancelar el pedido #${this.ordenId}?`;
    },
  },
};
</script>
<style lang="scss">
.ldng {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em;
}
</style>
