var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-danger",
      "title": _vm.title,
      "centered": "",
      "hide-footer": "",
      "modal-class": "modal-danger"
    },
    on: {
      "ok": _vm.cancelarPedido,
      "hide": function hide($event) {
        return _vm.$emit('hide');
      }
    },
    model: {
      value: _vm.mShow,
      callback: function callback($$v) {
        _vm.mShow = $$v;
      },
      expression: "mShow"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "ldng"
  }, [_c('h5', [_vm._v("Cancelando orden...")]), _c('b-spinner')], 1) : _c('div', [_c('b-card-text', [_vm._v(" Es posible que esto genere molestias con el cliente y debe ser utilizado como último recurso. Si presentas alguna novedad con la orden y no podrás despacharla, puedes cancelarla. Por favor, realizar esto en caso de ser necesario y estar seguro de querer cancelar la orden. ")]), _c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.cancelarPedido
    }
  }, [_vm._v("Si, CANCELAR el pedido")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }