var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.productosData.length > 0 ? _c('div', {
    staticClass: "asdmnka"
  }, [_vm.showIncompleteProductButton ? _c('div', {
    staticStyle: {
      "align-self": "flex-end",
      "display": "flex"
    }
  }, [_vm.showRestoreProductButton ? _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickRestoreProduct();
      }
    }
  }, [_vm.loadingRestore ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('small', {
    staticClass: "m-0"
  }, [_vm._v(" Restaurar producto incompleto ")])], 1) : _vm._e(), _c('b-button', {
    staticClass: "mb-1 ml-1",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickIncompleteProduct();
      }
    }
  }, [_vm.loadingSet ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('small', {
    staticClass: "m-0"
  }, [_vm._v(" Marcar producto incompleto ")])], 1)], 1) : _vm._e(), _vm._l(_vm.productosData, function (product) {
    return _c('b-card', {
      key: 'producto-' + product.producto_id,
      staticClass: "ecommerce-card",
      attrs: {
        "no-body": ""
      }
    }, [_c('div', {
      staticClass: "item-img",
      staticStyle: {
        "padding": "0em 1em"
      }
    }, [_c('b-img', {
      attrs: {
        "src": _vm.urlResized(product.imagen),
        "alt": "".concat(product.nombre, "-").concat(product.producto_id),
        "rounded": "",
        "width": "90",
        "height": "90"
      }
    })], 1), _c('b-card-body', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('div', {
      staticClass: "dcnt"
    }, [_c('div', {
      staticClass: "item-name"
    }, [_c('h6', {
      staticClass: "mb-0 text-primary"
    }, [_vm._v(" " + _vm._s(product.nombre) + " ")])]), _c('span', [_vm._v("Código: "), _c('b', [_vm._v(_vm._s(product.sku))])]), _c('span', [_vm._v("Precio Unitario: "), _c('b', [_vm._v("$" + _vm._s(product.precioUnitario.toFixed(2)))])]), _c('b-badge', {
      staticClass: "product-details-quantity",
      attrs: {
        "variant": "light-dark"
      }
    }, [_vm._v("Cantidad: "), _c('b-badge', {
      staticClass: "ml-25",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(product.cantidad))])], 1), _vm.productIsIncomplete(product) ? _c('span', {
      staticClass: "text-danger"
    }, [_vm._v("Producto Incompleto: "), _c('b', [_vm._v(_vm._s(_vm.productIncompleteMessage(product)))])]) : _vm._e(), product.observacion ? _c('div', {
      staticClass: "mt-1 text-left p-1 rounded bg-light-warning"
    }, [_c('b', [_c('svg', {
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "viewBox": "0 0 24 24",
        "width": "16",
        "height": "16"
      }
    }, [_c('path', {
      attrs: {
        "fill": "none",
        "d": "M0 0h24v24H0z"
      }
    }), _c('path', {
      attrs: {
        "d": "M4 20v-6a8 8 0 1 1 16 0v6h1v2H3v-2h1zm2 0h12v-6a6 6 0 1 0-12 0v6zm5-18h2v3h-2V2zm8.778 2.808l1.414 1.414-2.12 2.121-1.415-1.414 2.121-2.121zM2.808 6.222l1.414-1.414 2.121 2.12L4.93 8.344 2.808 6.222zM7 14a5 5 0 0 1 5-5v2a3 3 0 0 0-3 3H7z"
      }
    })]), _vm._v(" Indicaciones")]), _vm._v(": " + _vm._s(product.observacion) + " ")]) : _vm._e(), _c('br'), product.lstModificadores.length > 0 ? _c('app-collapse', {
      attrs: {
        "type": "border",
        "accordion": ""
      }
    }, [_c('app-collapse-item', {
      staticClass: "mdc",
      attrs: {
        "title": "Modificadores",
        "isVisible": true
      }
    }, [_c('b-list-group', _vm._l(product.lstModificadores, function (modificador, index) {
      var _modificador$modNombr;
      return _c('b-list-group-item', {
        key: index + '[mod]'
      }, [_c('p', {
        staticClass: "mb-1"
      }, [_vm._v(_vm._s((_modificador$modNombr = modificador.modNombreInterno) !== null && _modificador$modNombr !== void 0 ? _modificador$modNombr : modificador.ModNombreInterno))]), _vm._l(modificador.items, function (item, index) {
        var _item$cantidad, _item$nombre, _item$precio, _item$precio2, _item$precio3;
        return _c('b-media', {
          key: index + '[item]' + item.idCarritoItems,
          staticClass: "mhj",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', [_c('b-badge', {
          attrs: {
            "variant": "light-dark"
          }
        }, [_vm._v(" " + _vm._s((_item$cantidad = item.cantidad) !== null && _item$cantidad !== void 0 ? _item$cantidad : item.Cantidad))])], 1), _c('b-media-body', [_vm._v(" " + _vm._s((_item$nombre = item.nombre) !== null && _item$nombre !== void 0 ? _item$nombre : item.Nombre) + " "), ((_item$precio = item.precio) !== null && _item$precio !== void 0 ? _item$precio : item.Precio) ? _c('b', [_vm._v("(" + _vm._s(((_item$precio2 = item.precio) !== null && _item$precio2 !== void 0 ? _item$precio2 : item.Precio) ? ((_item$precio3 = item.precio) !== null && _item$precio3 !== void 0 ? _item$precio3 : item.Precio).toFixed(2) : 0 .toFixed(2)) + ")")]) : _vm._e()])], 1);
      })], 2);
    }), 1)], 1)], 1) : _vm._e()], 1)]), _c('div', {
      staticStyle: {
        "padding": "0em 1em"
      }
    }, [_c('div', {
      staticClass: "text-center"
    }, [_c('h6', {
      staticClass: "m-0"
    }, [_vm._v("SubTotal")]), _c('h4', {
      staticClass: "item-price m-0"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" $" + _vm._s(product.subtotal.toFixed(2)))])], 1)])])], 1);
  }), _c('b-modal', {
    ref: "modal-incomplete-product-ref",
    attrs: {
      "id": "modal-incomplete-product",
      "title": _vm.modalIncompleteTitle,
      "centered": "",
      "ok-title": "Aceptar",
      "cancel-title": "Cancelar",
      "ok-variant": "danger",
      "cancel-variant": "outline-secondary",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.setProductIncomplete
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Producto incompleto:",
      "label-for": "v-products"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-products",
      "label": "nombre",
      "reduce": function reduce(p) {
        return p.producto_id;
      },
      "options": _vm.productosData,
      "clearable": false,
      "placeholder": "Seleccione el producto incompleto"
    },
    model: {
      value: _vm.selectedProduct,
      callback: function callback($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  })], 1), _c('b-form-group', [_c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "id": "v-incomplete-message",
      "placeholder": "Mensaje",
      "type": "text"
    },
    model: {
      value: _vm.incompleteMessage,
      callback: function callback($$v) {
        _vm.incompleteMessage = $$v;
      },
      expression: "incompleteMessage"
    }
  })], 1)], 1), _c('b-modal', {
    ref: "modal-restore-product-ref",
    attrs: {
      "id": "modal-restore-product",
      "title": _vm.modalCompleteTitle,
      "centered": "",
      "ok-title": "Confirmar",
      "cancel-title": "Cancelar",
      "cancel-variant": "outline-secondary",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.setProductComplete
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Producto incompleto:",
      "label-for": "v-products"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-products",
      "label": "nombreProducto",
      "reduce": function reduce(p) {
        return p.productoID;
      },
      "options": _vm.incompleteProducts,
      "clearable": false,
      "placeholder": "Seleccione el producto incompleto"
    },
    model: {
      value: _vm.selectedProductComplete,
      callback: function callback($$v) {
        _vm.selectedProductComplete = $$v;
      },
      expression: "selectedProductComplete"
    }
  })], 1)], 1)], 2) : _c('b-alert', {
    staticClass: "m-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertCircleIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("No hay productos para mostrar. Comunicarse con soporte@elrosado.com para reportar el problema")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }